import { useNavigate } from "react-router-dom";
import "../styles/styles.scss"
import { useFetchFolder } from "../hooks/useFetchFolder";
import Loader from "../components/Loader";
import ErrorPage from "../components/ErrorPage";
import { useFetchImage } from "../hooks/useFetchImage";

function Header() {
    const navigate = useNavigate()

    const [state, error, images] = useFetchFolder("icons/navigation")

    const [devonState, devonImage] = useFetchImage("icons/devon.png")

    if (state === "Loading" || devonState === "Loading")
        return (<Loader />)
    else if (state === "Failed" || devonState === "Failed")
        return (<ErrorPage />)
    return (
        <section className="header">
            <img
                src={devonImage}
                alt={devonImage}
                title="home"
                onClick={() => { navigate("/") }}
            />
            <div className="nav">
                {images.filter(img => img.name !== "background" && img.name !== "footer").map((item) => {
                    return <img
                        key={item.link}
                        className="icons"
                        src={item.link}
                        alt={item.name}
                        title={item.name}
                        onClick={() => { navigate(item.name) }}
                    />
                })}
            </div>
        </section>
    )
}

export default Header;