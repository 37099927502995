import Gallery from "../components/Gallery";
import "../styles/styles.scss"
import { useFetchFolder } from "../hooks/useFetchFolder";
import useFetchMultipleFolders from "../hooks/useFetchMultipleFolders";

function Clothes() {
    // const [state, error, images] = useFetchMultipleFolders("clothes/lookbook")
    const [folderData, isLoading, error] = useFetchMultipleFolders('clothes', ["lookbook"])

    return (
        <Gallery data={folderData} error={error} isLoading={isLoading} />
    )
}

export default Clothes