import '../styles/styles.scss';
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

function Modal({ data, index, setIndex, shown, setShown }: Readonly<{ data: any, index: number, setIndex: any, shown: boolean, setShown: any }>) {

    return (
        <>
            {shown && (
                <main className="Modal">
                    <AiOutlineLeft className="Arrows" style={index === 0 ? { visibility: "hidden" } : {}} onClick={() => setIndex(index - 1)} />
                    <div key={index} className="Modal__IMG">
                        <img key={index} src={data.length === 0 ? '' : data[index]} alt={data.length === 0 ? '' : data[index]} />
                    </div>
                    <AiOutlineRight className="Arrows" style={index === data.length - 1 ? { visibility: "hidden" } : {}} onClick={() => setIndex(index + 1)} />
                    <div className="Modal__BG" onClick={() => setShown(false)} />
                </main>
            )}
        </>
    );
}

export default Modal;