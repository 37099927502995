import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../Firebase";
import { useEffect, useState } from "react";

export const useFetchImage = (path: string): ["Failed" | "Loading" | "Success", string] => {
    const [state, setState] = useState<"Failed" | "Loading" | "Success">("Loading")
    const [imageResult, setImageResult] = useState<string>("")

    async function fetchImage() {
        try {
            const imageRef = ref(storage, path);
            const imageUrl = await getDownloadURL(imageRef)
            setState("Success")
            setImageResult(imageUrl)
        } catch (error) {
            setState("Failed")
        }
    }

    useEffect(() => {
        fetchImage()
    }, [])

    return [state, imageResult]
}