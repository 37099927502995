import Gallery from "../components/Gallery";
import "../styles/styles.scss"
import useFetchMultipleFolders from "../hooks/useFetchMultipleFolders";

function BookCovers() {
    const [folderData, isLoading, error] = useFetchMultipleFolders("book-covers", ["art"])


    return (
        <Gallery data={folderData} error={error} isLoading={isLoading} />
    )
}

export default BookCovers