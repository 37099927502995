import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Illustrations from "./pages/Illustrations";
import Footer from "./components/Footer";
import "./styles/styles.scss"
import Comics from "./pages/Comics";
import Clothes from "./pages/Clothes";
import Photography from "./pages/Photography";
import Contact from "./pages/Contact";
import BookCovers from "./pages/BookCovers";
import Header from "./components/Header";


function App() {
  return (
    <div className="app-root">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="illustration" element={<Illustrations />} />
        <Route path="/comics" element={<Comics />} />
        <Route path="/clothing" element={<Clothes />} />
        <Route path="/photography" element={<Photography />} />
        <Route path="/book-covers" element={<BookCovers />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
