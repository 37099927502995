import '../styles/styles.scss';

function ErrorPage() {

    return (
        <div className="centered">
            <h1>Oopsie woopsie I made a poopsie</h1>
        </div>
    )
}

export default ErrorPage