import useFetchMultipleFolders from "../hooks/useFetchMultipleFolders";
import Gallery from "../components/Gallery";
import "../styles/styles.scss"

function Comics() {
    const [folderData, isLoading, error] = useFetchMultipleFolders('comics', ["bob's cafe", "eat me", "love by lamplight", "misc"])

    return (
        <Gallery data={folderData} error={error} isLoading={isLoading} />
    )
}

export default Comics