import { useState, useEffect } from "react";
import '../styles/styles.scss';
import Modal from "./Modal";
import Loader from "./Loader";
import ErrorPage from "./ErrorPage";

function Gallery({ data, isLoading, error }: Readonly<{ data: any, isLoading: boolean, error: Error | null }>) {
    //Get Data from Firestore and filters it
    const [filtered, setFiltered] = useState([]);
    const [filter, setFilter] = useState("all");

    //Handle Modal
    const [shown, setShown] = useState(false);
    const [modalIndex, setModalIndex] = useState(0);

    useEffect(() => {
        if (data['all']) {
            setFiltered(data['all'])
        }
    }, [data])

    //Sets filtered data
    useEffect(() => {
        if (filtered.length > 0) {
            setModalIndex(0)
            setFiltered(data[filter]);
        }
    }, [filter])

    if (isLoading)
        return (<Loader />)
    else if (error)
        return (<ErrorPage />)
    else
        return (
            <main className="GalleryWrapper">
                {/* Filters */}
                {Object.keys(data).length > 2 && <div className="Filters" >
                    {Object.keys(data).map(section =>
                        <h5 key={section} className={filter === section ? "SelectedFilter" : ""} onClick={() => { setFilter(section) }}>{section}</h5>
                    )}
                </div>}
                {/* Modal */}
                <Modal shown={shown} setShown={setShown} index={modalIndex} setIndex={setModalIndex} data={filtered} />
                {/* Gallery */}
                <section className="Gallery">
                    {filtered.map((link: string, index: number) => (
                        <img
                            key={link}
                            className={link}
                            src={link}
                            alt={link}
                            title={link}
                            onClick={() => { setShown(true); setModalIndex(index) }}
                        />
                    ))}
                </section>
            </main>
        )
}

export default Gallery;