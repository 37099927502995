import useFetchMultipleFolders from "../hooks/useFetchMultipleFolders";
import Gallery from "../components/Gallery";
import "../styles/styles.scss"

function Illustrations() {
    const [folderData, isLoading, error] = useFetchMultipleFolders('illustration', ['food', 'personal', 'band posters', 'book covers', 'children books', 'junior thesis'])

    return (
        <Gallery data={folderData} error={error} isLoading={isLoading} />
    )
}

export default Illustrations