import "../styles/styles.scss"
import { aboutPage } from "../utils/applicationText";

function HomePage() {

    return (
        <main className="homePage">
            <h1>{aboutPage.desciption}</h1>
        </main>
    )
}

export default HomePage;